import React from 'react';
import { motion } from 'framer-motion';

interface SocialLinkProps {
  Logo: string;
  name: string;
  link: string;
}

const SocialLink: React.FC<SocialLinkProps> = ({ Logo, name, link }) => (
  <motion.a
    href={link}
    className="flex items-center justify-start mb-4 text-gray-700 hover:text-gray-900 transition-colors duration-300"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <img src={Logo} alt={name} className="w-8 h-8 mr-3 object-contain" />
    <span className="text-lg">{name}</span>
  </motion.a>
);

export default SocialLink;