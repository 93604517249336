import React from "react";
import { motion } from "framer-motion";
import FlexerLogo from "../assets/images/FlexerLogoRounded.png";
import XLogo from "../assets/images/XLogo.png";
import InstagramLogo from "../assets/images/InstagramLogo.png";
import TikTokLogo from "../assets/images/TikTokLogo.png";
import YouTubeLogo from "../assets/images/YouTubeLogo.png";
import CalendlyLogo from "../assets/images/CalendlyLogo.png";
import EmailIcon from "../assets/images/EmailIcon.jpg";
import ProfilePicture from "../assets/images/ProfilePicture.jpeg";
import SocialLink from "./SocialLink";

const HomePage: React.FC = () => {
  return (
    <div className="min-h-screen bg-white flex flex-col justify-start items-center p-4 pt-16 sm:pt-24 md:pt-32">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl mx-auto"
      >
        <header className="flex flex-row items-center mb-4 justify-start">
          <img
            src={ProfilePicture}
            alt="Dillon Trembath"
            className="w-16 h-16 rounded-full mr-4 object-cover"
          />
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-800"
          >
            Dillon Trembath
          </motion.h1>
        </header>
        <main className="flex flex-col items-start w-full">
          <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.5 }}
            className="mb-4 w-full"
          >
            <h2 className="text-lg sm:text-xl md:text-2xl font-semibold text-gray-800 mb-2">
              Apps
            </h2>
            <div className="flex flex-col w-full">
              <SocialLink
                Logo={FlexerLogo}
                name="Flexer"
                link="https://flexerworkout.app/"
              />
            </div>
          </motion.section>
          <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.5 }}
            className="mb-4 w-full"
          >
            <h2 className="text-lg sm:text-xl md:text-2xl font-semibold text-gray-800 mb-2">
              Socials
            </h2>
            <div className="flex flex-col w-full">
              <SocialLink
                Logo={XLogo}
                name="X"
                link="https://x.com/dillontrembath"
              />
              <SocialLink
                Logo={InstagramLogo}
                name="Instagram"
                link="https://www.instagram.com/dillontrembath"
              />
              <SocialLink
                Logo={TikTokLogo}
                name="TikTok"
                link="https://www.tiktok.com/@dillontrembath"
              />
              <SocialLink
                Logo={YouTubeLogo}
                name="YouTube"
                link="https://www.youtube.com/@dillontrembath"
              />
            </div>
          </motion.section>
          <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.8, duration: 0.5 }}
            className="w-full"
          >
            <h2 className="text-lg sm:text-xl md:text-2xl font-semibold text-gray-800 mb-2">
              Connect
            </h2>
            <div className="flex flex-col w-full">
              <SocialLink
                Logo={EmailIcon}
                name="dillontrembath@gmail.com"
                link="mailto:dillontrembath@gmail.com"
              />
            </div>
          </motion.section>
        </main>
      </motion.div>
    </div>
  );
};

export default HomePage;
