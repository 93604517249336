import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.css';
import App from './App';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyCjOwThCKI1xakV3eFvU0jYpkfVa8P9lYs",
  authDomain: "personalwebsite-10950.firebaseapp.com",
  projectId: "personalwebsite-10950",
  storageBucket: "personalwebsite-10950.appspot.com",
  messagingSenderId: "33127944464",
  appId: "1:33127944464:web:e4a150cd13368891695595",
  measurementId: "G-JDS8FM7JYR"
};

const app = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);